import { create } from "zustand";
import Cart from "@/models/Cart";
import toast from "react-hot-toast";
import Product from "@/models/Products";
import { getUserCard, removeItemFromCart } from "@/pages/api/cart/route";

interface ProductStore {
  products: any[];
  loading: boolean;
  getCardTotal: () => void;
  fetchProducts: () => void;
  removeItemFromCart: (id: string) => void;
  // fetchProductById: (id: string) => Promise<void>
  // fetchProductsByCategory: (categoryId: string) => Promise<void>;
}

export const useProductStore: any = create<ProductStore>((set) => ({
  products: [],
  loading: false,

  fetchProducts: async (force: boolean = false) => {
    if (useProductStore.getState().products.length && !force) return;
    set({ loading: true });
    Cart.index()
      .then((res: any) => {
        set({ products: res.data });
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.message);
      });
    set({ loading: false });
  },

  getCardTotal: () => {
    return useProductStore
      .getState()
      .products.reduce((total: number, item: any) => {
        return total + item.price * item.quantity;
      }, 0);
  },

  removeItemFromCart: async (id: string) => {
    toast.loading("Retirer du panier...");
    Cart.delete(id)
      .then((res: any) => {
        const newCart = useProductStore
          .getState()
          .products.filter((item: any) => item.id !== id);
        set({ products: newCart });
        toast.dismiss();
      })
      .catch((err: any) => {
        toast.error(err.message);
      });
  },

  // fetchProductById: async (id: string) => {
  //   set({ loading: true })
  //   try {
  //     const response = await Product.getProduct(id)
  //     set({ currentProduct: response.data, error: null })
  //   } catch (error) {
  //     set({ error: 'Failed to fetch product' })
  //   } finally {
  //     set({ loading: false })
  //   }
  // },

  // fetchProductsByCategory: async (categoryId: string) => {
  //   set({ loading: true });
  //   try {
  //     const response = await Product.getByCategory(categoryId);
  //     set({ products: response.data, error: null });
  //   } catch (error) {
  //     set({ error: "Failed to fetch category products" });
  //   } finally {
  //     set({ loading: false });
  //   }
  // },
}));
