"use client";

import React from "react";
import Header from "@/components/Header/Header";
import { useThemeMode } from "@/hooks/useThemeMode";
import HeaderLogged from "@/components/Header/HeaderLogged";

const SiteHeader = () => {
  useThemeMode();
  return <HeaderLogged />;
};

export default SiteHeader;
