"use client";

import "./globals.css";
import "@/styles/index.scss";
import { Poppins } from "next/font/google";
import "@/fonts/line-awesome-1.3.0/css/line-awesome.css";

import "rc-slider/assets/index.css";
import SiteHeader from "@/app/SiteHeader";
import CommonClient from "./CommonClient";
import Footer from "@/shared/Footer/Footer";
import { SessionProvider } from "next-auth/react";
import TanstackProvider from "@/providers/providers.client";

const poppins = Poppins({
  subsets: ["latin"],
  display: "swap",
  weight: ["300", "400", "500", "600", "700"],
});

export default function RootLayout({
  children,
  params,
}: {
  children: React.ReactNode;
  params: any;
}) {
  return (
    <html lang="en" dir="" className={poppins.className}>
      <SessionProvider>
        <body className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
          <div>
            <SiteHeader />
            <TanstackProvider>{children}</TanstackProvider>
            <Footer />
          </div>
          <CommonClient />
        </body>
      </SessionProvider>
    </html>
  );
}
