import React from "react";
import Image from "next/image";
import Logo from "@/shared/Logo/Logo";
import { CustomLink } from "@/data/types";

import facebook from "@/images/socials/facebook.svg";
import twitter from "@/images/socials/twitter.svg";
import telegram from "@/images/socials/telegram.svg";
import youtube from "@/images/socials/youtube.svg";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const socials: { name: string; icon: any; href: string }[] = [
  { name: "Facebook", icon: facebook, href: "#" },
  { name: "Youtube", icon: youtube, href: "#" },
  { name: "Telegram", icon: telegram, href: "#" },
  { name: "Twitter", icon: twitter, href: "#" },
];

const widgetMenus: WidgetFooterMenu[] = [
  // {
  //   id: "5",
  //   title: "Getting started",
  //   menus: [
  //     { href: "/", label: "Release Notes" },
  //     { href: "/", label: "Upgrade Guide" },
  //     { href: "/", label: "Browser Support" },
  //     { href: "/", label: "Dark Mode" },
  //   ],
  // },
  // {
  //   id: "1",
  //   title: "Explore",
  //   menus: [
  //     { href: "/", label: "Prototyping" },
  //     { href: "/", label: "Design systems" },
  //     { href: "/", label: "Pricing" },
  //     { href: "/", label: "Security" },
  //   ],
  // },
  // {
  //   id: "2",
  //   title: "Resources",
  //   menus: [
  //     { href: "/", label: "Best practices" },
  //     { href: "/", label: "Support" },
  //     { href: "/", label: "Developers" },
  //     { href: "/", label: "Learn design" },
  //   ],
  // },
  // {
  //   id: "4",
  //   title: "Community",
  //   menus: [
  //     { href: "/", label: "Discussion Forums" },
  //     { href: "/", label: "Code of Conduct" },
  //     { href: "/", label: "Contributing" },
  //     { href: "/", label: "API Reference" },
  //   ],
  // },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-20  border-t border-neutral-200 dark:border-neutral-700">
      <div className="container flex items-center justify-center flex-col space-y-4">
        <Logo className="w-56" />

        <div className="flex gap-3">
          {socials.map((item: any, index: number) => (
            <a
              href={item.href}
              className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
              key={index}
            >
              <div className="flex-shrink-0 w-5 ">
                <Image sizes="40px" src={item.icon} alt="" />
              </div>
              <span className="hidden lg:block text-sm">{item.name}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
